const hasSearch = {
  methods: {
    searchQuery() {
      // console.log('mixins hasSearch.js methods searchQuery()');
      this.$emit('searchQuery');
    },
  },
};

// leave the export, even if you don't use it
export default hasSearch;
